<template>
  <div class="about">
    <div class="page-section about-us">
      <div class="page-container">
        <div class="w-layout-grid home-2-grid about-us resetMargin">
          <div id="w-node-58c9c0b3b97d-5e77d31f" class="title-text-container">
            <div class="title-text center-aligned">About Us</div>
          </div>
          <div id="w-node-58c9c0b3b980-5e77d31f" class="content-text-container">
            <div class="content-text center-aligned">
              Students who have graduated from the Vedanta Academy have started
              centers all over the world. These centers aim at providing the
              knowledge of Vedanta to all.
            </div>
          </div>
          <div id="w-node-58c9c0b3b983-5e77d31f" class="content-text-container">
            <div class="content-text center-aligned about-us">
              Vedanta Spain was started with the same motive to make people
              aware of this knowledge, how it helps them and how it can be
              applied. We conduct weekly study classes where the human nature
              and its relationship with the world are studied and discussed.
              This makes one aware of how one contacts the world. How to assess
              and relate to the environment and people around. How to become
              mentally peaceful at all times and productive in one’s fields of
              action. And how to reach the ultimate goal which is a mental
              state<br />of absolute Bliss.<br />Everyone is welcome to attend
              the classes.<br />Meet like-minded people to learn about the art
              of living.<br /><br />Please contact us if you would like to join
              or host a class.<br />All of our activities are done with the
              attitude of service.<br />If anyone interested in supporting the
              cause, they can do so!
            </div>
          </div>
          <img src="images/vedanta-spain-aboutus.jpg" loading="lazy" id="w-node-58c9c0b3b987-5e77d31f" alt=""
          class="about-us">
          <div class="image-container about-us"></div>
        </div>
      </div>
    </div>
    <div class="page-section our-teachers">
      <div class="page-container">
        <div class="w-layout-grid home-2-grid our-teachers">
          <div id="w-node-837ec5455c8c-5e77d31f" class="title-text-container">
            <div class="title-text left-aligned">Our Teachers</div>
          </div>
          <div
            id="w-node-837ec5455c95-5e77d31f"
            class="image-container our-teachers"
          >
            <img
              src="images/vedanta-katharina.png"
              loading="lazy"
              width="720"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 96vw, (max-width: 991px) 90vw, 720px"
              srcset="
                images/vedanta-katharina-p-500.png  500w,
                images/vedanta-katharina-p-800.png  800w,
                images/vedanta-katharina.png       1440w
              "
              alt=""
            />
          </div>
          <div
            id="w-node-7681ecbc758d-5e77d31f"
            class="image-container our-teachers"
          >
            <img
              src="images/vedanta-rishanth.png"
              loading="lazy"
              width="720"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 96vw, (max-width: 991px) 90vw, 720px"
              srcset="
                images/vedanta-rishanth-p-500.png  500w,
                images/vedanta-rishanth.png       1440w
              "
              alt=""
            />
          </div>
          <div
            id="w-node-837ec5455c8f-5e77d31f"
            class="content-text-container our-teachers"
          >
            <div class="content-text center-aligned">
              Katharina Lang, born and brought up in Germany, studied and
              practiced Physiotherapy in Germany. In 2013 Katharina took up the
              3-year residential course at the Vedanta Academy in India. After a
              brief stay in Germany upon completing the course in 2016 she
              returned and stayed in the Academy to deepen the understanding of
              Vedanta and taught classes in Pune and Mumbai until 2018.<br />Today
              she is based in Barcelona and has been conducting weekly study
              classes.
            </div>
          </div>
          <div
            id="w-node-b3acf2f8fec9-5e77d31f"
            class="content-text-container our-teachers"
          >
            <div class="content-text center-aligned">
              Rishanth Harish, born and brought up in India, studied mechanical
              engineering and worked in a valves company in Dubai. He joined the
              Vedanta Academy in 2013 to pursue the 3-year residential course.
              After completion of the course in 2016 Rishanth continued to stay
              at the Academy for two more years to consolidate his understanding
              on the subject. In these two years he took weekly classes in
              Mumbai and conducted Vedanta workshops in Vedary Wellness Clinics
              Mumbai.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "About-Us",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style>
.resetMargin {
  margin-top: 30px !important;
}
@media screen and (max-width: 479px) {
  .resetMargin {
    margin-top: 20px !important;
  }
}
</style>
